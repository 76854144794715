'use client';

import type { Role } from '@repo/api-gw-sdk';
import { createContext, type PropsWithChildren } from 'react';

import { useDAL } from '@/data/dal';

import { useUser } from './useUser';

const permissions = {
  'View global settings': {
    scopes: ['read:account_users', 'read:idp_configs'],
    category: 'Account',
  },
  'Manage user access': {
    scopes: ['admin:account_users'],
    category: 'Account',
  },
  'Manage group access': { scopes: ['admin:idp_configs'], category: 'Account' },
  'Manage custom roles': {
    scopes: ['admin:account_roles'],
    category: 'Account',
  },
  'Manage source accounts': {
    scopes: ['admin:source_accounts'],
    category: 'Account',
  },
  'Manage projects': { scopes: ['admin:projects'], category: 'Account' },

  'View dashboard': { scopes: ['read:dashboard'], category: 'View' },
  'View inventory': {
    scopes: ['read:inventory', 'read:policies', 'read:vaults', 'read:controls'],
    category: 'View',
  },
  'View jobs': { scopes: ['read:jobs'], category: 'View' },
  'View audit logs': { scopes: ['read:audit_logs'], category: 'View' },
  'View backup settings': {
    scopes: [
      'read:backup_settings',
      'read:vaults',
      'read:restore_accounts',
      'read:policies',
      'read:controls',
    ],
    category: 'View',
  },

  'Search tables': {
    scopes: ['read:db'],
    category: 'View',
    group: 'Search and explore',
  },
  'Explore database schemas': {
    scopes: ['read:db_explorer'],
    category: 'View',
    group: 'Search and explore',
  },
  'Search files': {
    scopes: ['read:files'],
    category: 'View',
    group: 'Search and explore',
  },
  'Explore file systems': {
    scopes: ['read:file_explorer'],
    category: 'View',
    group: 'Search and explore',
  },

  'Take snapshots on demand': {
    scopes: ['create:jobs'],
    category: 'Operation',
  },
  'Restore resources': {
    scopes: [
      'create:restore_resource',
      'read:restore',
      'read:restore_accounts',
    ],
    category: 'Operation',
  },
  'Restore files': {
    scopes: ['create:restore_file', 'read:restore', 'read:restore_accounts'],
    category: 'Operation',
    group: 'Restore granular data',
  },
  'Query databases': {
    scopes: ['create:db'],
    category: 'Operation',
    group: 'Restore granular data',
  },

  'Override data classes': {
    scopes: ['update:data_classification'],
    category: 'Operation',
    group: 'Override classifications',
  },
  'Override environments': {
    scopes: ['update:environment_classification'],
    category: 'Operation',
    group: 'Override classifications',
  },
  'Override detected apps': {
    scopes: ['update:apps_classification'],
    category: 'Operation',
    group: 'Override classifications',
  },

  'Mute and unmute violations': {
    scopes: ['update:control_violations'],
    category: 'Backup management',
  },
  'Exclude and allow backing up': {
    scopes: ['update:exclude_resource'],
    category: 'Backup management',
  },
  'Manage vaults': { scopes: ['admin:vaults'], category: 'Backup management' },
  'Manage restore accounts': {
    scopes: ['admin:restore_accounts'],
    category: 'Backup management',
  },
  'Manage backup policies': {
    scopes: ['admin:policies'],
    category: 'Backup management',
  },
  'Manage controls': {
    scopes: ['admin:controls'],
    category: 'Backup management',
  },
};

const isAuthorized = (scope: string, roleScopes: string[]) => {
  const [permission, action] = scope.split(':');
  const optionalScopes = [
    scope,
    `${permission}:all`,
    `admin:${action}`,
    'admin:all',
  ];
  return optionalScopes.some((x) => roleScopes.includes(x));
};

export const RolesContext = createContext<{
  roles: Role[];
  rolesMap: Record<string, Role>;
  permissions: Record<
    string,
    { scopes: string[]; category: string; group?: string; description?: string }
  >;
  isAuthorized: (scope: string, scopesOverride?: string[]) => boolean;
  refreshRoles: () => void;
} | null>(null);

export const RolesProvider = ({ children }: PropsWithChildren) => {
  const dal = useDAL();

  const { user } = useUser();

  const { body: rolesBody, mutate: reloadRoles } = dal.roles.list();

  const roles = (rolesBody?.roles ?? []).sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const rolesMap = Object.assign(
    {},
    ...roles.map((role) => ({ [role.id]: role }))
  );

  return (
    <RolesContext.Provider
      value={{
        roles,
        rolesMap,
        permissions,
        isAuthorized: (scope, scopesOverride) =>
          isAuthorized(
            scope,
            scopesOverride || user?.selectedRole.scopes || []
          ),
        refreshRoles: () => void reloadRoles(),
      }}
    >
      {children}
    </RolesContext.Provider>
  );
};
